// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_sz d_bD";
export var storyRowWrapper = "v_hx d_hx d_bK";
export var storyLeftWrapper = "v_sB d_bz d_bP";
export var storyWrapperFull = "v_sC d_cD";
export var storyWrapperFullLeft = "v_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "v_mv d_hy";
export var storyLeftWrapperCenter = "v_sD d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "v_sF d_hF";
export var storyHeader = "v_sG d_hD d_w d_cs";
export var storyHeaderCenter = "v_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "v_hB d_hB d_by d_dw";
export var storyBtnWrapper = "v_sH d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "v_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "v_sJ d_fg d_Z";
export var imageWrapperFull = "v_sK d_w d_H d_bf d_Z";
export var SubtitleSmall = "v_qp q_qp q_qN q_qZ";
export var SubtitleNormal = "v_qq q_qq q_qN q_q0";
export var SubtitleLarge = "v_qr q_qr q_qN q_q1";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";
// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "l_jQ d_jQ d_cv";
export var galleryMasonryImage = "l_jP d_jP d_w d_bR d_dB";
export var alignLeft = "l_pB d_fp d_bG d_dv";
export var alignCenter = "l_bP d_fq d_bD d_dw";
export var alignRight = "l_pC d_fr d_bH d_dx";
export var galleryContainer = "l_pD d_dW";
export var galleryContainerFull = "l_pF d_dT";
export var galleryRowWrapper = "l_pG d_cc";
export var galleryGuttersImage = "l_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "l_jR d_jR d_K d_cD";
export var galleryTextGutters = "l_jM d_jM d_cw";
export var galleryTextNoGutters = "l_jN d_jN d_cw";
export var galleryTextMasonry = "l_pH d_jM d_cw";
export var galleryImageWrapper = "l_pJ d_fg d_Z";
export var descText = "l_pK d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "l_pL";
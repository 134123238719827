// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "t_sv d_gS d_cw d_dv";
export var quoteParagraphCenter = "t_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "t_gV d_gV d_cw d_dx";
export var quoteRowLeft = "t_sw d_bG";
export var quoteRowCenter = "t_sx d_bD";
export var quoteRowRight = "t_sy d_bH";
export var quoteWrapper = "t_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "t_gR d_gR";
export var quoteExceptionSmall = "t_rC q_rC";
export var quoteExceptionNormal = "t_rD q_rD";
export var quoteExceptionLarge = "t_rF q_rF";
export var quoteAuthorExceptionSmall = "t_rG q_rG";
export var quoteAuthorExceptionNormal = "t_rH q_rH";
export var quoteAuthorExceptionLarge = "t_rJ q_rJ";
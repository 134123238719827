// extracted by mini-css-extract-plugin
export var alignLeft = "s_pB d_fp d_bG d_dv";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignRight = "s_pC d_fr d_bH d_dx";
export var menuContainer = "s_ss d_dW";
export var menuContainerFull = "s_st d_dT";
export var menuMainHeader = "s_jC d_jC d_w d_c3";
export var menuComponentWrapper = "s_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "s_jH d_jH d_cy";
export var menuComponentText = "s_jD d_jD d_cn";
export var menuComponentTextDesign2 = "s_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "s_jJ d_jJ d_w d_Z";